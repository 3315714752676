<template>
  <div class="container mt-5 text-center">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title text-success">Payment Successful!</h1>
        <p class="card-text">Thank you for your purchase. Your transaction has been completed successfully.</p>
        <button class="btn btn-primary" @click="goToHomePage">Go to Home Page</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  methods: {
    goToHomePage() {
      this.$router.push('/patient_dashboard');
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 100%;
  margin: auto;
  border: 1px solid #28a745;
}
.card-title {
  color: #28a745;
}
</style>
